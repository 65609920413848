import getConfig from 'next/config'

import { MetaData } from '@web/components/common/MetaData'
import { GlobalProps } from '@web/types/app'

import Error from './_error.page'

const { publicRuntimeConfig } = getConfig()

export default function Custom404({ globalProps }: Custom404Props) {
  return (
    <>
      <MetaData
        baseUrl={
          globalProps?.brand === 'cornette'
            ? publicRuntimeConfig.CORNETTE_BASE_URL
            : globalProps?.brand === 'skinner'
              ? publicRuntimeConfig.SKINNER_BASE_URL
              : publicRuntimeConfig.WEB_BASE_URL
        }
      />
      <Error statusCode={404} />
    </>
  )
}

interface Custom404Props {
  globalProps?: GlobalProps
}
