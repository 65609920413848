import styled, { createGlobalStyle } from 'styled-components'

import { Body, Heading } from '@nx/fire/typography'

/* istanbul ignore next */
export const ErrorPageGlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme: { colours } }) => colours.primary.white} !important;
  }
`

export const StyledErrorPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colours } }) => colours.primary.white};
  padding: 45px 15px;
  margin: 0 -15px -60px;

  ${({ theme: { media } }) => media.up.lg} {
    padding: 70px 0;
    margin-top: 10px;
  }
`

export const StyledContent = styled.div`
  text-align: center;
  padding: 20px;
`

export const StyledTitle = styled(Heading)`
  && {
    margin-bottom: 20px;
  }
`

export const StyledSubTitle = styled(Body)`
  && {
    font-weight: 400;

    ${({ theme: { media } }) => media.up.lg} {
      margin-bottom: 60px;
    }
  }
`

export const StyledButtons = styled.div`
  display: grid;
  margin: 10px 0;
  grid-auto-flow: row;
  gap: 20px;
  width: 100%;
  max-width: 375px;

  ${({ theme: { media } }) => media.up.lg} {
    gap: 32px;
    width: fit-content;
    grid-auto-flow: column;
    max-width: 100%;
  }
`
