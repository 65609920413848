import * as Sentry from '@sentry/nextjs'
import { NextPage } from 'next'

import { GenericError } from '@web/components/common/Error'

const ErrorPage: NextPage<ErrorProps> = (props) => <GenericError {...props} />

ErrorPage.getInitialProps = ({ res, err }) => {
  if (err) {
    if (err.statusCode !== 404) {
      Sentry.captureException(err)
    }
    if (typeof window == 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-require-imports
      const newrelic = require('newrelic')
      newrelic.noticeError(err)
    } else {
      window.newrelic?.noticeError?.(err)
    }
  }

  const statusCode = res ? res.statusCode : err && err.statusCode
  return { statusCode: statusCode ?? 404 }
}

interface ErrorProps {
  statusCode: number
}

export default ErrorPage
