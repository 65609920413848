import getConfig from 'next/config'

import { FireButton } from '@nx/fire/button'
import { FireImage } from '@nx/fire/image'
import { Section } from '@nx/fire/section'
import { getTranslate } from '@nx/translations'

import {
  ErrorPageGlobalStyle,
  StyledButtons,
  StyledContent,
  StyledErrorPage,
  StyledSubTitle,
  StyledTitle,
} from '../CustomError.styles'
import { StyledImage } from './GenericError.styles'
import translate from './GenericError.translate.json'

const { publicRuntimeConfig } = getConfig()
const { text } = getTranslate(translate)

export const GenericError = ({ statusCode }: { statusCode: number }) => {
  const code: 403 | 404 | 500 =
    statusCode === 403 || statusCode === 404
      ? (statusCode as 403 | 404 | 500)
      : 500

  return (
    <Section>
      <ErrorPageGlobalStyle />
      <StyledErrorPage>
        <StyledImage>
          <FireImage
            src="/web-assets/errorPlane.png"
            width="203"
            height="191"
            alt={text('error.plane')}
            disableBackgroundPlaceholder
          />
        </StyledImage>
        <StyledContent>
          <StyledTitle $level={2} as="h1">
            {text(`error.${code}.Title`)}
          </StyledTitle>
          <StyledSubTitle $level="L" as="p">
            {text(`error.${code}.Description`)}
          </StyledSubTitle>
        </StyledContent>
        <StyledButtons>
          {statusCode === 403 && (
            <FireButton
              href={`${publicRuntimeConfig.WEB_BASE_URL}/stories/30262/`}
            >
              {text(`error.403.Button`)}
            </FireButton>
          )}

          {statusCode === 500 && (
            <FireButton onClick={() => window.location.reload()}>
              {text(`error.500.Button`)}
            </FireButton>
          )}

          <FireButton
            variant={statusCode === 404 ? 'primary' : 'secondary'}
            href="/auctions/upcoming/"
          >
            {text('error.browse')}
          </FireButton>
        </StyledButtons>
      </StyledErrorPage>
    </Section>
  )
}
